import React, { useState } from 'react'
import {
  Box,
  TextField,
  makeStyles,
  IconButton,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import * as EmailValidator from 'email-validator'
import ArrowRight from './graphics/ArrowRight'

const useStyles = makeStyles((theme) => ({
  newsletter: {
    color: theme.palette.primary.grey,
    fill: theme.palette.primary.grey,
  },
  iconButton: {
    position: 'relative',
  },
  circularProgress: {
    color: '#b3bcc5',
    left: '3px',
    position: 'absolute',
    top: 0,
  },
  success: {
    minHeight: '57.5px',
  },
}))

const EmailNewsletterForm = () => {
  const classes = useStyles()
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [started, setStarted] = useState(false)

  const encode = (data) => Object.keys(data)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`,
    )
    .join('&')

  const postData = (params) => fetch(
    `https://s1487871083.t.eloqua.com/e/f2?${encode(params)}`,
    {
      method: 'POST',
    },
  )

  const sendStarted = () => {
    if (!started) {
      window.dataLayer.push({
        event: 'form_start',
        identifier: 'NewsLetterSignUp',
        name: 'News Letter SignUp Form',
        type: 'News Letter SignUp Form',
      })

      setStarted(true)
    }
  }

  const handleSubmit = async (evt) => {
    setSubmitting(true)
    evt.preventDefault()
    setDisabled(true)
    if (EmailValidator.validate(email)) {
      setEmailError('')
      try {
        await postData({
          elqFormName: 'Rise-Subscribe',
          elqSiteID: '1487871083',
          email,
        })
        window.dataLayer.push({
          event: 'form_complete',
          identifier: 'NewsLetterSignUp',
          name: 'News Letter SignUp Form',
          type: 'News Letter SignUp Form',
        })

        setSuccess(true)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        window.dataLayer.push({
          event: 'form_error',
          identifier: 'NewsLetterSignUp',
          name: 'News Letter SignUp Form',
          type: 'News Letter SignUp Form',
          error_message: err,
        })

        setEmailError('Oops something went wrong.')
      }
    } else {
      window.dataLayer.push({
        event: 'form_error',
        identifier: 'NewsLetterSignUp',
        name: 'News Letter SignUp Form',
        type: 'News Letter SignUp Form',
        error_message: 'Incorrect email format.',
      })
      setEmailError('Incorrect email format.')
    }
    setDisabled(false)
    setSubmitting(false)
  }

  return success ? (
    <Box className={classes.success}>
      <Typography variant="h5">Thank you for joining our community! Get ready to Rise.</Typography>
    </Box>
  ) : (
    <Box component="form" onSubmit={handleSubmit} itemProp={{ novalidate: 'true' }} className="newsletter-form">
      <TextField
        id="newsletter-email-input"
        label="Enter your Email"
        fullWidth
        error={emailError !== ''}
        helperText={emailError}
        value={email}
        disabled={disabled}
        onChange={({ target: { value } }) => setEmail(value)}
        onFocusCapture={sendStarted}
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="Email Newsletter Submit Button"
              className={classes.iconButton}
              disabled={disabled}
              type="submit"
            >
              {submitting && <CircularProgress className={classes.circularProgress} />}
              <ArrowRight className={classes.newsletter} />
            </IconButton>
          ),
        }}
      />
    </Box>
  )
}

export default EmailNewsletterForm
