import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'

const useStyles = makeStyles({
  arrowRight: {
    display: 'block',
    height: '100%',
    width: '100%',
    maxWidth: '20.25px',
    maxHeight: '17.5px',
  },
})

const ArrowRight = ({ className }) => {
  const classes = useStyles()

  return (
    <>
      <Hidden xsUp><p>Arrow Right</p></Hidden>
      <SvgIcon className={[classes.arrowRight, className].join(' ')} viewBox="0 0 81 70">
        <path fillRule="evenodd" clipRule="evenodd" d="M48.6626 1.20842C47.8337 0.432057 46.7252 -0.00136866 45.5719 4.53217e-06C44.6846 -0.00197156 43.817 0.253193 43.0808 0.73264C42.3446 1.21209 41.7736 1.89389 41.4411 2.69024C41.1087 3.48658 41.03 4.36103 41.2154 5.20101C41.4008 6.04098 41.8416 6.80801 42.4812 7.40335L67.7526 31L4 31C1.79087 31 3.25293e-06 32.7909 3.0598e-06 35C2.86667e-06 37.2091 1.79087 39 4 39L67.6285 39L42.4812 62.4807C42.0353 62.8675 41.6745 63.3373 41.4205 63.8621C41.1665 64.3868 41.0245 64.9558 41.0029 65.535C40.9813 66.1142 41.0806 66.6916 41.2948 67.2327C41.5091 67.7739 41.8339 68.2677 42.2498 68.6845C42.6657 69.1013 43.1642 69.4327 43.7155 69.6587C44.2668 69.8847 44.8595 70.0008 45.4581 70C46.0568 69.9992 46.6492 69.8815 47.1997 69.6539C47.7503 69.4264 48.2479 69.0937 48.6626 68.6757L78.3513 40.9623C79.1888 40.1831 79.8551 39.2483 80.3103 38.2138C80.7384 37.2406 80.9714 36.1982 80.9975 35.142C80.9992 35.0949 81 35.0475 81 35C81 34.992 81 34.9839 80.9999 34.9759C81 34.9646 81 34.9533 81 34.9421C81 33.8176 80.7654 32.7048 80.3103 31.6703C79.8551 30.6358 79.1888 29.701 78.3513 28.9218L48.6626 1.20842Z" />
      </SvgIcon>
    </>
  )
}

ArrowRight.defaultProps = {
  className: null,
}

ArrowRight.propTypes = {
  className: PropTypes.string,
}

export default ArrowRight
