import React from 'react'
import PropTypes from 'prop-types'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'

const Triangle = ({ color, mixBlendMode }) => (
  <>
    <Hidden xsUp>
      <p>Triangle</p>
    </Hidden>
    <SvgIcon
      style={{ width: '100%', height: '100%' }}
      fontSize="large"
      viewBox="0 0 588 488"
      fill="none"
      className="shape"
    >
      <g style={{ mixBlendMode }}>
        <path
          d="M181.579 64.1148C181.9 41.5077 206.164 27.3668 225.992 38.2313L560.642 221.598C581.109 232.813 581.495 262.076 561.332 273.827L221.266 472.015C201.103 483.767 175.832 469.006 176.164 445.67L181.579 64.1148Z"
          fill={color}
        />
      </g>
    </SvgIcon>
  </>
)

Triangle.propTypes = {
  color: PropTypes.string,
  mixBlendMode: PropTypes.string,
}

Triangle.defaultProps = {
  color: '#FF5B16',
  mixBlendMode: 'screen',
}

export default Triangle
