import React, { useState, useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { PropTypes } from 'prop-types'
import { useIntersect } from '../utils/hooks'
import { map } from '../utils/numbers'
import './intersect-box.scss'


const { format } = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 })

const buildThresholdArray = () => Array.from(Array(100).keys(), (i) => i / 100)
const useStyles = makeStyles({
  fadeTriggerBox: {
    transition: 'opacity 0.4s ease',
    position: 'relative',
    opacity: 0.2,
    '&$active': {
      opacity: 1,
    },
  },
  active: {},
})
const FadeBox = ({ ratio, children }) => (
  <Box style={{ opacity: `${map(ratio, 0, 1, 0.25, 1) || 1}` }}>{children}</Box>
)

FadeBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  ratio: PropTypes.number.isRequired,
}

const SlideBox = ({ ratio, intersectComponentStyles, children }) => (
  <Box
    style={{
      transform: `translate3d(${map(ratio, 0, 1, -20, 0)}%, 0 ,0)`,
      transition: 'translate 0.1s ease',
      opacity: `${map(ratio, 0, 0.75, 0.25, 1) || 1}`,
      ...intersectComponentStyles,
    }}
  >
    {children}
  </Box>
)

const SlideUpBox = ({ ratio, children, intensity }) => (
  <Box
    style={{
      transform: `translate3d(0,${map(ratio, 0, 1, intensity, 0)}%, 0)`,
      transition: 'translate 0.1s ease',
      opacity: `${map(ratio, 0, 0.75, 0, 1) || 1}`,
    }}
  >
    {children}
  </Box>
)

SlideUpBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool])),
    PropTypes.element,
  ]).isRequired,
  ratio: PropTypes.number.isRequired,
  intensity: PropTypes.number.isRequired,
}

SlideBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  ratio: PropTypes.number.isRequired,
  intersectComponentStyles: PropTypes.shape({}),
}

SlideBox.defaultProps = {
  intersectComponentStyles: {},
}

const FadeTriggerBox = ({ ratio, threshold = 0.7, children }) => {
  const classes = useStyles()

  return (
    <Box
      className={`${classes.fadeTriggerBox} ${
        ratio >= threshold ? classes.active : ''
      }`}
    >
      {children}
    </Box>
  )
}

const RiseTriggerBox = ({
  ratio, enterThreshold, exitThreshold, children, direction,
}) => {
  const [active, setActive] = useState(false)
  useEffect(() => {
    if (active && ratio < exitThreshold) {
      setActive(false)
    }
    if (!active && ratio > enterThreshold) {
      setActive(true)
    }
  }, [ratio])
  return (
    <Box
      className={`rise-trigger-box ${
        active ? 'active' : ''
      } ${direction}`}
    >
      {children}
    </Box>
  )
}

RiseTriggerBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool])),
    PropTypes.element,
  ]).isRequired,
  ratio: PropTypes.number,
  enterThreshold: PropTypes.number,
  exitThreshold: PropTypes.number,
  direction: PropTypes.string,
}

RiseTriggerBox.defaultProps = {
  enterThreshold: 0.4,
  exitThreshold: 0.15,
  ratio: 0,
  direction: '',
}

FadeTriggerBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool])),
    PropTypes.element,
  ]).isRequired,
  ratio: PropTypes.number,
  threshold: PropTypes.number,
}

FadeTriggerBox.defaultProps = {
  threshold: 0.68,
  ratio: 0,
}

const BoxTypes = {
  fade: FadeBox,
  fadeTrigger: FadeTriggerBox,
  riseTrigger: RiseTriggerBox,
  slideBox: SlideBox,
  slideUp: SlideUpBox,
  undefined: ({ children }) => children,
}

const IntersectBox = ({
  children,
  type,
  allowTriggerReset,
  onThresholdMet,
  onExitThreshold,
  threshold,
  exitThreshold,
  className,
  style,
  rootMargin,
  intensity,
  intersectComponentStyles,
}) => {
  const [hasTriggered, setHasTriggered] = useState(false)
  const [ref, entry] = useIntersect({
    threshold: buildThresholdArray(),
    rootMargin,
  })
  const debug = false
  const Component = type ? BoxTypes[type] : null
  useEffect(() => {
    if (
      onThresholdMet
      && !hasTriggered
      && entry.intersectionRatio >= threshold
    ) {
      onThresholdMet()
      setHasTriggered(true)
    } else if (
      hasTriggered
      && allowTriggerReset
      && entry.intersectionRatio < threshold && !exitThreshold
    ) {
      setHasTriggered(false)
      if (onExitThreshold) {
        onExitThreshold()
      }
    } else if (
      hasTriggered
      && allowTriggerReset
      && exitThreshold
      && entry.intersectionRatio < exitThreshold
    ) {
      setHasTriggered(false)
      if (onExitThreshold) {
        onExitThreshold()
      }
    }
  }, [entry.intersectionRatio])

  return (
    <div ref={ref} className={className} style={style}>
      {Component ? (
        <Component
          intersectComponentStyles={intersectComponentStyles}
          ratio={entry.intersectionRatio || 0}
          intensity={intensity}
        >
          {debug && (
            <div style={{ position: 'absolute', top: '50%', left: 0 }}>
              intersectionRatio:
              {format(entry.intersectionRatio)}
            </div>
          )}
          {children}
        </Component>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

IntersectBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  type: PropTypes.string,
  allowTriggerReset: PropTypes.bool,
  onThresholdMet: PropTypes.func,
  onExitThreshold: PropTypes.func,
  intensity: PropTypes.number,
  threshold: PropTypes.number,
  exitThreshold: PropTypes.number,
  className: PropTypes.string,
  rootMargin: PropTypes.string,
  style: PropTypes.shape({}),
  intersectComponentStyles: PropTypes.shape({}),
}

IntersectBox.defaultProps = {
  onThresholdMet: null,
  onExitThreshold: null,
  threshold: 0.5,
  exitThreshold: null,
  allowTriggerReset: false,
  type: null,
  className: '',
  rootMargin: '0px',
  style: {},
  intensity: 0,
  intersectComponentStyles: {},
}

export default IntersectBox
