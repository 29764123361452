import React from 'react'
import { PropTypes } from 'prop-types'
import { Box } from '@material-ui/core'
import Image from '../PreviewCompatibleImage'

const Texture = ({ texture, variation }) => {
  if (!texture) return null

  const textureVariation = texture[variation]

  return textureVariation ? (
    <Box style={{ pointerEvents: 'none' }}>
      <Image
        imageInfo={textureVariation}
        style={{ zIndex: 10, opacity: 0.5 }}
      />
    </Box>
  ) : null
}

Texture.propTypes = {
  texture: PropTypes.shape({
    blue: PropTypes.shape({}),
    green: PropTypes.shape({}),
    grey: PropTypes.shape({}),
  }).isRequired,
  variation: PropTypes.oneOf(['blue', 'green', 'grey']).isRequired,
}

const makeTexture = (texture) => ({ variation, deg = 0 }) => (
  <Texture texture={texture} variation={variation} deg={deg} />
)

export default makeTexture
