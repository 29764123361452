export const map = (value, inMin, inMax, outMin, outMax) => ((value - inMin)
  * (outMax - outMin))
  / (inMax - inMin) + outMin

export const LAYERS_DELTA = typeof window !== 'undefined'
  ? [
    window.innerHeight / 8,
    window.innerHeight / 4,
    window.innerHeight / 3,
    window.innerHeight / 2,
  ]
  : [0, 0, 0, 0]
