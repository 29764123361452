import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'

import InteractiveNewsletterBlock from './InteractiveNewsletter'

const NewsletterBlock = ({ texture }) => (
  <StaticQuery
    query={graphql`
      query Desktop {
        desktop: imageSharp(
          resize: { originalName: { eq: "newsletter-desktop1.png" } }
        ) {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        desktop2: imageSharp(
          resize: { originalName: { eq: "newsletter-desktop2.png" } }
        ) {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        desktop3: imageSharp(
          resize: { originalName: { eq: "newsletter-desktop3.png" } }
        ) {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        mobile: imageSharp(
          resize: { originalName: { eq: "newsletter-mobile.png" } }
        ) {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({
      mobile, desktop, desktop2, desktop3,
    }) => (
      <InteractiveNewsletterBlock
        texture={texture}
        mobile={mobile}
        desktop={desktop}
        desktop2={desktop2}
        desktop3={desktop3}
      />
    )}
  />
)

NewsletterBlock.propTypes = {
  texture: PropTypes.shape({}),
}

NewsletterBlock.defaultProps = {
  texture: null,
}

export default NewsletterBlock
