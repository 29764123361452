import React from 'react'
import PropTypes from 'prop-types'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'

const Circle = ({ color }) => (
  <>
    <Hidden xsUp>
      <p>Circle</p>
    </Hidden>
    <SvgIcon
      style={{ width: '100%', height: '100%' }}
      fontSize="large"
      viewBox="0 0 324 324"
      fill="none"
      className="shape"
    >
      <g style={{ mixBlendMode: 'screen' }}>
        <circle cx="162.185" cy="162.41" r="161.59" fill={color} />
      </g>
    </SvgIcon>
  </>
)

Circle.propTypes = {
  color: PropTypes.string,
}

Circle.defaultProps = {
  color: '#40A36D',
}

export default Circle
