import { useRef, useState, useEffect } from 'react'

if (typeof window !== 'undefined' && !window.IntersectionObserver) {
  // eslint-disable-next-line global-require
  require('intersection-observer')
}

export const useHover = () => {
  const [value, setValue] = useState(false)

  const ref = useRef(null)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(
    () => {
      const node = ref.current
      if (node) {
        node.addEventListener('mouseenter', handleMouseOver)
        node.addEventListener('mouseleave', handleMouseOut)

        return () => {
          node.removeEventListener('mouseenter', handleMouseOver)
          node.removeEventListener('mouseleave', handleMouseOut)
        }
      }

      return node
    },
    [ref.current], // Recall only if ref changes
  )

  return [ref, value]
}

export const useIntersect = ({ root = null, rootMargin = '0px', threshold = 0 }) => {
  const [entry, updateEntry] = useState({})
  const [node, setNode] = useState(null)
  const observer = useRef(
    typeof window !== 'undefined' && window.IntersectionObserver
      ? new window.IntersectionObserver(([e]) => updateEntry(e), {
        root,
        rootMargin,
        threshold,
      })
      : null,
  )

  useEffect(() => {
    const { current: currentObserver } = observer
    if (currentObserver) {
      currentObserver.disconnect()
    }

    if (node && currentObserver) currentObserver.observe(node)

    return () => {
      if (currentObserver) {
        currentObserver.disconnect()
      }
    }
  }, [node])

  return [setNode, entry]
}
