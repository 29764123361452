import React from 'react'
import PropTypes from 'prop-types'
import {
  Container, Box, Grid, Typography, Hidden,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import EmailNewsletterForm from './EmailNewsletterForm'
import Image from './PreviewCompatibleImage'
import IntersectBox from './IntersectBox'
import makeTexture from './graphics/Texture'

const lerp = (a, b, n) => (1 - n) * a + n * b

const styles = () => ({
  copyContainer: {
    '& >div': {
      opacity: 0,
      transform: 'translate(0,35%)',
      transition: 'all 0.6s ease',
      '&:nth-child(2)': {
        transitionDelay: '0.18s',
      },
      '&:nth-child(3)': {
        transitionDelay: '0.36s',
      },
    },
    '&$active >div': {
      opacity: 1,
      transform: 'translate(0,0%)',
    },
  },
  imageContainer: {
    opacity: 0,
    transform: 'translate(20px,0)',
    transition: 'all 0.6s ease',
  },
  active: {
    '& $imageContainer': {
      transitionDelay: '0.2s',
      transform: 'translate(0px,0)',
      opacity: 1,
    },
  },
})

let winsize
const calcWinsize = () => {
  winsize = typeof window !== 'undefined'
    ? { width: window.innerWidth, height: window.innerHeight }
    : { width: 0, height: 0 }
}
calcWinsize()

const getMousePos = (e) => {
  let posx = 0
  let posy = 0
  if (e.clientX || e.clientY) {
    posx = e.clientX / winsize.width
    posy = e.clientY / winsize.height
  }
  return { x: posx, y: posy }
}
class InteractiveNewsletterBlock extends React.Component {
  constructor(props) {
    super(props)
    this.imageRef = React.createRef()
    this.imageRef2 = React.createRef()
    this.imageRef3 = React.createRef()
    this.mousePos = { x: 0, y: 0 }
    this.lastMousePos = { x: 0, y: 0 }
    this.shouldAnimate = false
    this.state = { active: false }
  }

  componentDidMount() {
    const mouseMoveFunc = (ev) => {
      this.mousePos = getMousePos(ev)
    }
    this.boundMouseMoveFunc = mouseMoveFunc.bind(this)
  }

  componentWillUnmount() {
    this.shouldAnimate = false
    window.removeEventListener('mousemove', this.boundMouseMoveFunc)
  }

  startAnimation() {
    requestAnimationFrame(() => this.updatePositions())
  }

  updatePositions() {
    this.lastMousePos.x = lerp(this.lastMousePos.x, this.mousePos.x, 0.15)
    this.lastMousePos.y = lerp(this.lastMousePos.y, this.mousePos.y, 0.15)
    if (this.imageRef && this.imageRef.current) {
      this.imageRef.current.style.transform = `scale(1.1) translate3d(${
        this.lastMousePos.x * 5 - 2.5
      }%,${this.lastMousePos.y * 7 - 3.5}%,0)`
      this.imageRef2.current.style.transform = `scale(1.1) translate3d(${
        this.lastMousePos.x * 3 - 1.5
      }%,${this.lastMousePos.y * 5 - 2.5}%,0)`

      this.imageRef3.current.style.transform = `scale(1.1) translate3d(${
        this.lastMousePos.x * 2 - 1
      }%,${this.lastMousePos.y * 3 - 1.5}%,0)`
    }

    if (this.shouldAnimate) {
      requestAnimationFrame(() => this.updatePositions())
    }
  }

  render() {
    const {
      texture, mobile, desktop, desktop2, desktop3, classes,
    } = this.props
    const { active } = this.state

    const Texture = texture ? makeTexture(texture) : null

    return (
      <IntersectBox
        position="relative"
        threshold={0.4}
        allowTriggerReset
        onThresholdMet={() => {
          window.addEventListener('mousemove', this.boundMouseMoveFunc)
          this.shouldAnimate = true
          this.setState({ active: true })
          this.startAnimation()
        }}
        onExitThreshold={() => {
          this.shouldAnimate = true
          this.setState({ active: false })
          window.removeEventListener('mousemove', this.boundMouseMoveFunc)
        }}
      >
        <Box
          style={{ backgroundColor: '#111F30' }}
          overflow="hidden"
          position="relative"
        >
          {Texture ? (
            <Box
              position="absolute"
              top="0"
              left="0"
              width="50%"
              maxWidth="820px"
              zIndex="0"
            >
              <Texture variation="grey" />
            </Box>
          ) : null}
          {Texture ? (
            <Box
              position="absolute"
              top="0"
              right="-20%"
              width="50%"
              maxWidth="820px"
              zIndex="10"
            >
              <Texture variation="grey" />
            </Box>
          ) : null}
          <Container maxWidth="xl" className={active ? classes.active : ''}>
            <Grid container style={{ paddingRight: '' }}>
              <Hidden mdUp>
                <Grid item xs={12} className={classes.imageContainer}>
                  <Box
                    px={3}
                    pt={6}
                    mx="auto"
                    maxWidth="400px"
                    position="relative"
                  >
                    <Box
                      position="absolute"
                      style={{
                        backgroundColor: '#307EDA',
                        borderRadius: '50%',
                        left: '0',
                        paddingBottom: '75%',
                        top: '10%',
                        transform: 'translateX(-50%)',
                        width: '75%',
                      }}
                    />
                    <Image
                      style={{ width: '100%' }}
                      imageInfo={{ childImageSharp: mobile }}
                    />
                    <Box
                      position="absolute"
                      style={{
                        backgroundColor: '#FF5B16',
                        borderRadius: '50%',
                        mixBlendMode: 'screen',
                        left: '100%',
                        paddingBottom: '64%',
                        bottom: '-5%',
                        transform: 'translateX(-50%)',
                        width: '64%',
                      }}
                    />
                  </Box>
                </Grid>
              </Hidden>
              <Grid
                item
                xs={12}
                md={6}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Box
                  mr={3}
                  py={5}
                  className={`${classes.copyContainer} ${
                    active ? classes.active : ''
                  }`}
                >
                  <Box mb={2.5}>
                    <Typography variant="h1" component="h2">
                      Lift yourself up!
                    </Typography>
                  </Box>
                  <Box mb={5}>
                    <Typography variant="body2">
                      Every day is a new beginning and an opportunity to Rise.
                      Join us for inspiring stories, tools, and tips from real
                      people - all delivered right to your inbox.
                    </Typography>
                  </Box>
                  <Box maxWidth="545px">
                    <EmailNewsletterForm />
                  </Box>
                </Box>
              </Grid>
              <Hidden smDown>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ position: 'relative', paddingRight: 0 }}
                  className={classes.imageContainer}
                >
                  <Box
                    position="absolute"
                    style={{
                      backgroundColor: '#307EDA',
                      borderRadius: '50%',
                      left: '0',
                      paddingBottom: '50%',
                      top: '-30%',
                      width: '50%',
                    }}
                  />
                  <Box
                    ref={this.imageRef2}
                    style={{
                      height: '100%',
                      width: '100%',
                      position: ' absolute',
                    }}
                  >
                    <Image
                      style={{
                        height: '100%',
                        width: '100%',
                        position: ' absolute',
                        marginRight: '-24px',
                      }}
                      imageInfo={{ childImageSharp: desktop3 }}
                    />
                  </Box>
                  <Box
                    ref={this.imageRef3}
                    style={{
                      height: '100%',
                      width: '100%',
                      position: ' absolute',
                    }}
                  >
                    <Image
                      style={{
                        height: '100%',
                        width: '100%',
                        position: ' absolute',
                        marginRight: '-24px',
                      }}
                      imageInfo={{ childImageSharp: desktop2 }}
                    />
                  </Box>
                  <Box ref={this.imageRef}>
                    <Image
                      style={{
                        height: '100%',
                        marginRight: '-24px',
                      }}
                      imageInfo={{ childImageSharp: desktop }}
                    />
                  </Box>
                  <Box
                    position="absolute"
                    style={{
                      backgroundColor: '#FF5B16',
                      borderRadius: '50%',
                      mixBlendMode: 'screen',
                      left: '100%',
                      paddingBottom: '75%',
                      top: '100%',
                      transform: 'translate(-50%, -40%)',
                      width: '75%',
                    }}
                  />
                </Grid>
              </Hidden>
            </Grid>
          </Container>
        </Box>
      </IntersectBox>
    )
  }
}

InteractiveNewsletterBlock.propTypes = {
  classes: PropTypes.shape(styles).isRequired,
  texture: PropTypes.shape({}),
  mobile: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
      }),
    }),
  }).isRequired,
  desktop: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
      }),
    }),
  }).isRequired,
  desktop2: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
      }),
    }),
  }).isRequired,
  desktop3: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({
        aspectRatio: PropTypes.number,
        src: PropTypes.string,
      }),
    }),
  }).isRequired,
}

InteractiveNewsletterBlock.defaultProps = {
  texture: null,
}

export default withStyles(styles, { withTheme: true })(
  InteractiveNewsletterBlock,
)
